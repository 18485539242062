import "./src/styles/global.css";
/*
import "./node_modules/flowbite/dist/flowbite.min";
*/

const robotoFontLink = document.createElement('link');
robotoFontLink.href = 'https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap';
robotoFontLink.rel = 'stylesheet';
document.head.appendChild(robotoFontLink);

const breeSerifFontLink = document.createElement('link');
breeSerifFontLink.href = 'https://fonts.googleapis.com/css2?family=Bree+Serif:wght@400;600&display=swap';
breeSerifFontLink.rel = 'stylesheet';
document.head.appendChild(breeSerifFontLink);
